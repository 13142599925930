import { FC, useMemo } from "react";

import {
  BorderTypeConfiguration,
  InteractionTheme,
} from "@sellernote/shared/src/sds-v2/styles/interactionStyle";

import { IconType } from "../../componentsToMoveToV1/Icon/DATA";
import { SvgIconComponent } from "../useSvgIcon";

type ButtonTheme =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "dangerStroke"
  | "scan";

/**
 * 디자인 시스템의 'Text'라는 유형의 버튼은 TextButton 컴포넌트를 사용한다.
 */
type ButtonSize =
  | "normal"
  | "small"
  | "smallMultiRow"
  | "xLarge"
  | "block"
  | "input";

type ButtonIconInfoPosition = "left" | "right";

interface CommonButtonProps {
  label: React.ReactNode;
  handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
}

interface ButtonPropsV1 extends CommonButtonProps {
  theme: ButtonTheme;
  size: ButtonSize;
  icon?: {
    position: ButtonIconInfoPosition;
    type: IconType;
  };
  width?: string;
}

type ButtonSizeV2 =
  | "small"
  | "normal"
  | "large"
  | "block"
  | "inputFillContainer";

export type ButtonIconInfo = {
  Icon: SvgIconComponent | undefined;
  position: ButtonIconInfoPosition;
};

interface ButtonPropsV2 extends CommonButtonProps {
  theme: InteractionTheme;
  borderType: BorderTypeConfiguration;
  size: ButtonSizeV2;
  width?: string;
  iconInfo?: ButtonIconInfo;
  buttonType?: "button" | "submit" | "reset";
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface UseButtonProps {
  size: ButtonSize;
}

type ButtonComponent = FC<ButtonPropsV1 | ButtonPropsV2>;

export default function useButton({ size }: UseButtonProps) {
  const getIconSize = () => {
    switch (size) {
      case "small":
      case "smallMultiRow": {
        return 1;
      }
      default: {
        return 1.5;
      }
    }
  };

  const iconSize = useMemo(getIconSize, [size]);

  return { iconSize };
}

export type {
  ButtonPropsV1,
  ButtonPropsV2,
  ButtonComponent,
  ButtonIconInfoPosition,
  ButtonSize,
  ButtonSizeV2,
  ButtonTheme,
  CommonButtonProps,
};
