import { detect } from "detect-browser";

import { TimeZone } from "./services/dayjs";
import {
  SellernoteAppLanguage,
  SellernoteAppName,
  SellernoteAppType,
} from "./types/common/common";

import { ShipdaCurrentLanguage } from "./i18n/i18nForShipda";

// TODO: shared/src/constants/common으로 이전

const browser = detect();

export const ARTIFICIAL_DELAY_MS = 500;

/**
 * 보통은 dev환경일때 process.env.NODE_ENV을 체크하는 것으로 하지만,
 * dev도 배포환경에서는 process.env.NODE_ENV가 production으로 나오므로 (production빌드 결과가 실행되는 것이므로)
 * 혼돈을 방지하고자 이런 상수를 만듦
 */
export const IS_UNDER_LOCAL_DEVELOPMENT = process.env.NODE_ENV !== "production";

export type AppEnv = "production" | "staging" | "development" | "local";

/**
 * 앱이 배포된 환경
 * - 정확한 배포환경으로 분기해야하는 경우(ex. production과 staging을 정확히 구분해 분기할 필요가있는 경우)만 이 상수를 사용한다.
 * - Production여부를 체크할때는 이 상수가 아닌, `IS_UNDER_PRODUCTION`을 사용해야한다.
 */
export const APP_ENV: AppEnv = (process.env.REACT_APP_ENV ||
  process.env.NEXT_PUBLIC_ENV ||
  process.env.STORYBOOK_ENV) as AppEnv;

/**
 * Production 배포 환경인지 확인
 * - ⚠️ staging도 Production 배포환경으로 간주됨에 유의 (staging과 production을 구분해야하는 경우 `APP_ENV` 상수를 사용한다)
 */
export const IS_UNDER_PRODUCTION: boolean = (() => {
  return APP_ENV === "production" || APP_ENV === "staging";
})();

export const IS_ON_BROWSER = typeof window !== "undefined";

/**
 * 셀러노트 앱의 고유 이름
 * (환경변수를 통해 계산됨)
 */
export const APP_NAME = (() => getAppNameFromEnvVar())();

/**
 * 셀러노트 앱을 구분하는 카테고리
 * (환경변수를 통해 계산됨)
 */
export const APP_TYPE = (() => getAppTypeFromAppName(APP_NAME))();

/**
 * Production용 마케팅 트래킹을 사용하는지 여부
 * - 대부분의 경우 `Staging`이 `Production`과 동일하게 사용하나, 마케팅 트래킹같은 경우는 `Production`과 동일한 경우 문제가 생길 수 있어 이 상수를 통해 확인한다
 */
export const USES_PRODUCTION_TRACKING: boolean = (() => {
  if (!IS_UNDER_PRODUCTION) {
    return false;
  }

  if (APP_ENV === "staging") {
    if (APP_NAME === "partner-admin" || APP_NAME === "shipda-admin") {
      // staging환경이어도 admin인 경우는 마케팅 트래킹을 사용한다 (admin에서는 Production과 똑같이 사용하기 때문)
      return true;
    }

    // staging이면 마케팅 트래킹을 사용하지 않는다
    return false;
  }

  return true;
})();

/**
 * 앱의 기본 언어
 */
export const APP_DEFAULT_LANGUAGE: SellernoteAppLanguage = "ko";

/**
 * 앱의 기본 타임존
 */
export const APP_DEFAULT_TIMEZONE: TimeZone = "Asia/Seoul";

export type AppBuildInfo = {
  /** 빌드된 시각 (ISO 포맷) */
  builtAt: string;
  gitBranch: string;
  gitCommitSha: string;
};

/**
 * 앱의 빌드 정보.
 * 빌드 설정에 빌드정보를 추가하는 기능이 없으면 null이 반환됨.
 *
 * TODO: 이후에 version 정보도 추가하기
 * - git tag정보로 버전을 가져와야하는데 현재 vercel에서 git tag에 대한 환경변수 지원은 안 하고 있음. 향후 지원될 것으로 보이긴 함
 * - 참고: AB7910, https://github.com/orgs/vercel/discussions/166
 */
export const APP_BUILD_INFO: AppBuildInfo | null = (() => {
  const rawInfo =
    process.env.REACT_APP_APP_BUILD_INFO ||
    process.env.NEXT_PUBLIC_APP_BUILD_INFO;

  return rawInfo ? (JSON.parse(rawInfo) as AppBuildInfo) : null;
})();

function getAppNameFromEnvVar(): SellernoteAppName {
  const appName =
    process.env.REACT_APP_APP_NAME ||
    process.env.NEXT_PUBLIC_APP_NAME ||
    process.env.STORYBOOK_APP_NAME ||
    process.env.CI_APP_NAME;

  if (!appName) {
    throw new Error("cannot found *_APP_NAME from ENV");
  }

  return appName as SellernoteAppName;
}

/**
 * 셀러노트 앱을 구분하는 카테고리로서의 AppType를 가져옴
 */
function getAppTypeFromAppName(appName: SellernoteAppName): SellernoteAppType {
  switch (appName) {
    case "boful-worker-web": {
      return "Boful";
    }

    case "boful-worker-pda": {
      return "BofulMobile";
    }

    case "partner-admin":
    case "shipda-admin":
    case "shipda-kr": {
      return "ShipDa";
    }

    case "storybook": {
      return "Storybook";
    }

    case "jest-testing": {
      return "JestTesting";
    }

    case "ci": {
      return "CI";
    }

    default: {
      throw new Error("invalid APP_NAME");
    }
  }
}

export const PRODUCTION_URL: Pick<
  Record<SellernoteAppName, string>,
  "shipda-kr"
> = {
  "shipda-kr": "https://www.ship-da.com",
};

export const getServiceIntroductionInquiryUrl = () => {
  // 상수가 아닌 URL("https://whattime.co.kr/globalshipda/keyaccount")을 사용하는 컴포넌트 있음
  return {
    ko: "https://whattime.co.kr/globalshipda",
    en: "https://whattime.co.kr/ship-da-en",
  }[ShipdaCurrentLanguage.currentLanguage];
};

export const IS_READY_FOR_MSW_TOOL =
  IS_ON_BROWSER &&
  (browser?.name === "chrome" || browser?.name === "firefox") &&
  IS_UNDER_LOCAL_DEVELOPMENT;
